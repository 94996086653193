<template>
    <div id="page-managemenu" class="page-managemenu position-relative">
        <div class="bg-white rounded p-4 text-center" v-if="loadingPreparations">
            <b-spinner></b-spinner>
        </div>
        <div class="vstack gap-4" v-else>
            <div class="vstack gap-4 p-0" v-if="showTemplates && !menu._id">
                <div class="hstack justify-content-between bg-white rounded p-4 shadow-sm">
                    <div class="hstack gap-3 align-items-center">
                        <b-iconstack font-scale="4">
                            <b-icon stacked icon="circle-fill" variant="green"></b-icon>
                            <b-icon stacked icon="layout-text-window" scale=".5" variant="white"></b-icon>
                        </b-iconstack>
                        <div class="vstack justify-content-center">
                            <h5 class="m-0 lh-1">Lista de Templates</h5>
                            <div class="text-secondary small-text">{{ templatesTotal }} Templates Cadastrados</div>
                        </div>
                    </div>
                    <div>
                        <b-button class="py-1 px-4 text-white" pill variant="green" @click="createTemplate">Criar Novo Template</b-button>
                    </div>
                </div>
                <div class="row m-0 gap-4 justify-content-start">
                    <div :class="`col-4 bg-white rounded p-0 rounded shadow-sm`" v-for="(template, index) in templates" :key="index">
                        <div class="bg-dark text-white position-relative rounded-top">
                            <h3 class="text-center text-truncate py-6-5 m-0">
                                {{ template.name }}
                            </h3>
                            <div class="position-absolute top-0 end-0 m-4 d-flex gap-2">
                                <div v-b-tooltip.bottom.hover="{ customClass: 'top-0 mt-1', boundary: 'document' }" title="Editar">
                                    <b-iconstack class="darkenTextOnHover" font-scale="3" @click="pickMenu(template)" role="button">
                                        <b-icon stacked icon="circle-fill" variant="green"></b-icon>
                                        <b-icon stacked icon="pencil" scale=".5"></b-icon>
                                    </b-iconstack>
                                </div>
                                <div v-b-tooltip.bottom.hover="{ customClass: 'top-0 mt-1', boundary: 'document' }" title="Remover">
                                    <b-iconstack class="darkenTextOnHover" font-scale="3" @click="deleteTemplate(template)" role="button">
                                        <b-icon stacked icon="circle-fill" variant="red"></b-icon>
                                        <b-icon stacked icon="trash" scale=".5"></b-icon>
                                    </b-iconstack>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div class="px-4 py-3 border-bottom">
                                <h5>Canais Associados</h5>
                            </div>
                            <div class="p-4">
                                <span class="box-clamp-1" v-if="associatedChannels(template._id).length">
                                    <span class="text-secondary h6" v-for="(channel, i) in associatedChannels(template._id)" :key="channel._id">
                                        {{ channel.channelNumber | formatNumber }}<span v-if="i+1 < associatedChannels(template._id).length">,</span>
                                    </span>
                                </span>
                                <span class="text-secondary h6" v-else>
                                    ESTE TEMPLATE NÃO ESTÁ ASSOCIADO A UM CANAL!
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="d-flex justify-content-between align-items-center p-4 bg-white rounded shadow-sm" v-if="templates && templatesTotal > perPage">
                    <b-pagination class="m-0"
                        :total-rows="templatesTotal"
                        v-model="currentPage"
                        :per-page="perPage"
                        @change="pageChange"
                    ></b-pagination>
                    <span class="pagdescription">
                        <div class="text-secondary">{{ ( currentPage > 1 ) ? ((currentPage) * perPage) - perPage + 1 : currentPage }} - {{(currentPage * perPage > templatesTotal) ? templatesTotal : currentPage * perPage}} de {{ templatesTotal }} Templates Cadastrados</div>
                    </span>
                </div>
            </div>
            <b-row class="m-0 gap-4" v-if="menu._id && showTemplates">
                <b-col class="p-0 bg-white rounded shadow-sm">
                    <div>
                        <div class="cardheader">
                            <span class="cardnumber rounded-circle bg-green">01</span>
                            <h5 class="cardtitle">Nome do Template</h5>   
                        </div>
                        <div class="vstack gap-3 p-4">
                            <label for="menuName">
                                <div class="bg-green rounded text-white px-4 py-2">
                                    Nome do Template
                                </div>
                            </label>
                            <b-form-input id="menuName" v-model="menu.name"></b-form-input>
                        </div>
                    </div>
                </b-col>
                <b-col class="p-0 bg-white rounded shadow-sm">
                    <div>
                        <div class="cardheader">
                            <span class="cardnumber rounded-circle bg-green" style="font-size: 22px">02</span>
                            <h5 class="cardtitle">Números dos Canais Associados</h5>   
                        </div>
                        <div class="vstack gap-3 p-4">
                            <!-- <v-select class="searchSelect" :options="secondaryChannels" :filter="(options) => { return options.filter(el => el && (!el.menuTemplateId || el.menuTemplateId == menu._id)) }" :loading="loadingCRMOrganizations" :reduce="name => name?._id" label="name" @input="rdOrgChanged" @search="rdSearchOrganizations">
                                <div slot="no-options">Nenhum canal disponível!</div>
                            </v-select> -->
                            <div class="border rounded w-100 text-secondary py-2 px-3 d-flex justify-content-between" style="border-color: #eee !important" role="button" v-b-toggle.collapseChannelSelect v-if="channels.filter(el => el && (!el.channelConfig.menuTemplateId || el?.channelConfig.menuTemplateId === menu._id)).length">
                                <span class="box-clamp-1" v-if="channels.length">
                                    <span v-for="(channel,i) in templateChannels" :key="channel._id">
                                        <span>
                                            {{ channel.channelNumber | formatNumber }}<span v-if="i+1 < templateChannels.length">,</span> 
                                        </span>
                                    </span>
                                </span>
                                <span>
                                    <b-icon class="text-dark" icon="chevron-down" style="margin-right: -0.9em" font-scale="0.75"></b-icon>
                                </span>
                            </div>
                            <div class="border rounded w-100 text-secondary py-2 px-3" v-else>
                                Nenhum canal disponível
                            </div>
                            <b-collapse id="collapseChannelSelect" ref="collapseChannelSelect" class="mt-2 position-absolute border rounded" style="z-index: 1">
                                <b-form-checkbox-group
                                    class="bg-white"
                                    style="z-index: 1"
                                    v-model="templateChannels"
                                    @change="templateChannelSelected"
                                >
                                    <div v-for="item in channels.filter(el => el && (!el.channelConfig.menuTemplateId || el?.channelConfig.menuTemplateId === menu._id))" :key="item.id">
                                        <b-form-checkbox class="text-secondary small-text d-flex align-items-center border p-3 w-100" role="button" :value="item"> 
                                            <div class="ms-1 me-5" role="button">{{ item.channelNumber | formatNumber }}</div>
                                        </b-form-checkbox>
                                    </div>
                                </b-form-checkbox-group>
                                <div class="w-100 h-100 position-fixed top-0 end-0 start-0 bottom-0" role="window" style="z-index: -1" v-b-toggle.collapseChannelSelect>
                                </div>
                            </b-collapse>
                        </div>
                    </div>
                </b-col>
            </b-row>
            <b-row class="m-0" v-if="menu._id && showTemplates">
                <b-col class="p-0 bg-white rounded shadow-sm">
                    <div class="cardheader">
                        <span class="cardnumber rounded-circle bg-green" style="font-size: 22px">03</span>
                        <h5 class="cardtitle">Configuração do Template</h5>   
                    </div>
                </b-col>
            </b-row>
            <b-row class="managemenurow m-0 gap-4" v-if="menu._id">
                <b-col class="p-0 d-flex flex-column gap-4">
                    <b-card class="cardpresentation shadow-sm">
                        <div class="cardheader gap-3">
                            <b-iconstack font-scale="3.5">
                                <b-icon stacked icon="circle-fill" variant="green"></b-icon>
                                <b-icon stacked icon="display" scale=".5" variant="white"></b-icon>
                            </b-iconstack>
                            <h5 class="cardtitle">Apresentação</h5>   
                        </div>
                        <!-- <div class="cardsubheader border-bottom-1 d-flex text-center align-items-center flex-column p-4">
                            <div>A apresentação ocorre quando um usuário envia uma mensagem ao canal.</div>
                            <div>Aqui você pode escolher uma imagem, texto ou ambos, para a apresentação do canal.</div>
                        </div> -->
                       
                        <div class="addimage" v-if="menu.imageEnabled">
                            <b-form-file id="menuImgUpload" style="display:none" accept="image/*" @input="selectMenuImage"></b-form-file>
                            <label for='menuImgUpload' class="w-100">
                                <div class="cardpresentationimg" v-if="!menu.media[0]?.url">
                                    <div class="d-flex flex-column align-items-center">
                                        <b-icon class="cardpresentationicon" icon="image"></b-icon>
                                        <div class="cardpresentationtext">Adicionar Imagem</div>
                                    </div>
                                </div>
                                <div style="max-height:325px;" role="button" v-else>
                                    <b-img style="max-height: 325px; width: 100%" :src="menu.media[0].url"></b-img>
                                </div>
                            </label>
                        </div>
                        <div class="cardpresentationcontent">
                            <div class="cardpresentationcontenttop">
                                <b-form-checkbox class="cardpresentationcheckbox" v-model="menu.imageEnabled">Imagem</b-form-checkbox>
                                <!-- <b-button class="managemenubtn" v-b-modal.modal-carimbo>Inserir Carimbo</b-button> -->
                            </div>
                            <div class="textareaemoji-container" v-if="menu.textEnabled">
                                <b-form-textarea class="px-4" v-model="menu.text" placeholder="Escreva seu texto" rows="5" max-rows="5" no-resize></b-form-textarea>
                                <b-button class="toggle-emoji darkenTextOnHover" @click="toggleDialogEmojiTextarea"><b-icon icon="emoji-smile"></b-icon></b-button>
                                <VEmojiPicker @select="selectEmojiTextarea" lang="pt-BR" v-show="showDialogTextarea" :i18n="i18n" />
                            </div>
                            <div class="cardpresentationcontentbottom">
                                <b-form-checkbox class="cardpresentationcheckbox" v-model="menu.textEnabled">Texto</b-form-checkbox>
                                <div>
                                    <!-- <b-button class="managemenubtn" v-b-modal.modal-evasivas>Evasivas</b-button> -->
                                    <b-button class="managemenubtnpink" v-b-modal.modal-tags>Tags</b-button>
                                </div>
                            </div>
                        </div>
                    </b-card>
                </b-col>
                <b-col class="p-0 d-flex flex-column gap-4">      
                    <b-card class="cardmainmenu shadow-sm">
                        <div class="cardheader gap-3">
                            <b-iconstack font-scale="3.5">
                                <b-icon stacked icon="circle-fill" variant="green"></b-icon>
                                <b-icon stacked icon="list" scale=".5" variant="white"></b-icon>
                            </b-iconstack>
                            <h5 class="cardtitle">Menu Principal</h5>   
                        </div>
                        <div class="cardmainmenucontent">
                            <div>
                                <b-button class="managemenubtn bg-green border-0 text-center w-100 mb-3" @click="addMenu">Adicionar Novo Menu</b-button>
                            </div>
                            <draggable tag="ul" :list="menu.menus" class="list-group" handle=".handle" @end="checkMove">
                                <li
                                class="d-flex mb-2"
                                v-for="(item) in menu.menus"
                                :key="item.option"
                                >
                                    <b-form-input class="cardmainmenuitem cardmainmenunumber rounded" :placeholder="item.option" disabled></b-form-input>
                                    <div class="me-2 relative">
                                        <!-- <b-button class="cardmainmenuicon h-100 p-0" @click.prevent="selectItem(item,menu,$event)">
                                            <span v-if="item.icone">
                                                {{item.icone}}
                                            </span>
                                            <span v-else>
                                                Ícone
                                            </span>
                                        </b-button> -->
                                        <div class="cardmainmenuicon rounded d-flex h-100" v-if="item.icone">
                                            <b-button class=" h-100 p-0 flex-grow-1 rounded-end-0" variant="green3"  @click.prevent="selectItem(item,menu,$event)">
                                                {{item.icone}}
                                            </b-button>
                                            <b-button class="h-100 p-0 flex-grow-1 rounded-start-0" variant="danger" @click.prevent="item.icone = ''">
                                                <b-icon icon="x"></b-icon>
                                            </b-button>
                                        </div>
                                        <b-button class="cardmainmenuicon h-100 p-0" @click.prevent="selectItem(item,menu,$event)" v-else>
                                            Ícone
                                        </b-button>
                                        <VEmojiPicker class="iconemojipicker" @select="selectEmojiIcon" lang="pt-BR" v-show="showDialogIcon" :i18n="i18n" v-if="itemSelected" />
                                    </div>
                                    <b-input-group class="cardmainmenuitem">
                                        <template #append>
                                            <div class="bg-grey h-100 d-flex align-items-center px-2 rounded-end" v-b-tooltip.bottom="{ customClass: 'tooltip-orderChange' }" title="Alterar Ordem" role="button">
                                                <b-icon font-scale="1.75" icon="arrows-move" class="handle text-secondary"></b-icon>
                                            </div>
                                        </template>
                                        <b-form-input class="cardmainmenuitem cardmainmenuname rounded-start" v-model="item.textOption"></b-form-input>
                                    </b-input-group>
                                    <!-- <b-button class="cardmainmenuitem cardmainmenueyebtn"><b-icon icon="eye"></b-icon></b-button> -->
                                    <!-- <b-button class="cardmainmenuitem cardmainmenueditbtn rounded" v-b-tooltip.bottom="{ customClass: 'tooltip-edit' }" title="Editar" v-b-modal="`modal-editMenu${modalEditCount}`" @click="selectItem(item,menu); getRedirectData()"><b-icon icon="pencil-square"></b-icon></b-button> -->
                                    <b-button class="cardmainmenuitem cardmainmenuremovebtn rounded" v-b-tooltip.bottom="{ customClass: 'tooltip-remove' }" title="Remover" v-b-modal.modal-removeMenu @click.prevent="selectItemToDelete(item)"><b-icon icon="trash"></b-icon></b-button>
                                </li>
                            </draggable>
                            <div class="cardmainmenubtns mt-2">
                                <b-button class="managemenubtnpink" v-b-modal.preDefinedOptions>Opções Pré-Definidas</b-button>
                                <!-- <b-button class="ms-2" @click="closeDelete" v-if="remove">Cancelar</b-button> -->
                            </div>
                        </div>
                    </b-card>
                    <b-card class="d-flex align-items-center p-4 shadow-sm" v-if="!showTemplates">
                        <b-button class="managemenubtn bg-green3 border-green3" @click="saveMenu(true)">Avançar</b-button>
                        <b-button class="managemenubtnwhite" @click="goToMain">Cancelar</b-button>
                    </b-card>
                </b-col>
            </b-row>
            <div class="bg-white rounded d-flex align-items-center p-4 shadow-sm" v-if="showTemplates && menu._id">
                <b-button class="managemenubtn bg-green3 border-green3" @click="saveMenu(true)">Avançar</b-button>
                <b-button class="managemenubtnwhite" @click="cancel">Cancelar</b-button>
            </div>
        </div>
        <b-modal id="preDefinedOptions" ref="preDefinedOptions" title="Opções Pré-Definidas" header-class="py-0" body-class="p-0" hide-footer>
            <div class="text-purple">
                <div class="py-2 px-5 text-center small-text">
                    Essas opções não são visíveis. É necessário adicioná-las manualmente para aparecerem no menu.
                </div>
                <div class="p-3 border-top">
                    <div>
                        <span class="fw-bolder">*</span> - Finaliza Atendimento
                    </div>
                    <div>
                        <span class="fw-bolder">#</span> - Volta para o Menu Anterior
                    </div>
                </div>
            </div>
        </b-modal>
        <b-modal id="modal-carimbo" title="Carimbo">
            <b-row class="modalinputrow">
                <b-col>
                    <b-form-group label="Espaçamento Horizontal (X):">
                        <b-form-input></b-form-input>
                    </b-form-group>
                </b-col>
                <b-col>
                    <b-form-group label="Espaçamento Vertical (Y):">
                        <b-form-input></b-form-input>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row class="modalinputrow">
                <b-col>
                    <b-form-group label="Fonte:">
                        <b-form-select></b-form-select>
                    </b-form-group>
                </b-col>
                <b-col>
                    <b-row>
                        <b-col>
                            <b-form-group label="Tamanho:">
                                <b-form-select></b-form-select>
                            </b-form-group>
                        </b-col>
                        <b-col cols="3">
                            <b-form-group label="Cor:">
                                <b-form-input class="forminput" value="hsl(43deg 89% 38%)" type="color" list="presetColors"></b-form-input>
                            </b-form-group>
                        </b-col>
                    </b-row>
                </b-col>
            </b-row>
            <div>
                <b-button class="managemenubtn">Salvar</b-button>
                <b-button class="managemenubtnwhite" @click="$bvModal.hide('modal-carimbo')">Cancelar</b-button>
            </div>
        </b-modal>
        <b-modal id="modal-evasivas" class="managemenu-modal" title="Evasivas">
            <b>O que são evasivas?</b>
            <p>São aquelas palavras não identificadas pelo canal que o contato informa! A lista abaixo possui algumas palavras utilizadas pelo contato para tentar acessar os itens deste menu!</p>
            <div class="evasivas" v-for="item in evasivas" :key="item.id">{{item.name}}</div>
        </b-modal>
        <b-modal id="modal-tags" class="managemenu-modal" title="Tags" header-class="py-0 border-0" body-class="p-0">
            <div class="border-top text-secondary p-3" v-for="item in tags" :key="item.id">
                <b style="text-transform: uppercase">{{item.name}}</b> - {{item.description}}
            </div>
        </b-modal>
        <b-modal id="modal-removeMenu" ref="modal-removeMenu" title="Remover Menu" header-class="py-0" hide-footer v-if="itemToDelete">
            <div class="text-secondary">Tem certeza de que seja remover este menu?</div>
            <div class="mt-2">
                <b-button class="managemenubtnred" @click="deleteEl(itemToDelete)">Remover</b-button>
                <b-button class="ms-2 managemenubtnwhite" @click="$bvModal.hide('modal-removeMenu')">Cancelar</b-button>
            </div>
        </b-modal>
        <datalist id="presetColors">
            <option>#b8860b</option>
            <option>#018b8c</option>
            <option>#ff4500</option>
            <option>#ce5c5c</option>
            <option>#a0522c</option>
            <option>#ab5aa7</option>
            <option>#87cefa</option>
            <option>#ff69b3</option>
            <option>#ffd701</option>
            <option>#32cd33</option>
            <option>#ff8c00</option>
            <option>#d63031</option>
            <option>#a29bfe</option>
            <option>#dfe6e9</option>
            <option>#000000</option>
        </datalist>
    </div>
</template>

<script>
import { VEmojiPicker } from 'v-emoji-picker';
import draggable from 'vuedraggable'
import api from '../services/apiService.js'
import { getToken } from '../services/userService.js'

export default {
    components: {
        VEmojiPicker,draggable
    },
    props: ['user'],
    mounted: async function () {
        this.onCloseModal()
        await this.verifyTemplates()
    },
    computed: {
        fileAccept() {
            return function (type) {
                switch(type) {
                    case 'image':
                        return 'image/*'
                    case 'audio':
                        return 'audio/*'
                    case 'video':
                        return '.mp4'
                    case 'file':
                        return 'application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, text/plain, application/pdf,application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.openxmlformats-officedocument.presentationml.slideshow'
                }
            }
        },
        columnClass() {
            return this.templates.length <= 2 ? 'col' : 'col-md-4';
        }
    },
    methods: {
        onCloseModal() {
            this.$root.$on('bv::modal::hide',(bvEvent, modalId) => {
                if(modalId != 'modal-removeMenu') {
                    this.itemSelected = null;
                }
            })
        },
        async getMenus() {
            let resp = await api.getMenus(this.user.channelId || this.user.roleId)
            console.log('resp',resp)
            if(resp.statusCode == 200) {
                if(resp.menus.length) {
                    let mainMenuIndex = resp.menus.findIndex(el => !el.topMenuId)
                    this.menu = resp.menus[mainMenuIndex] // main menu
                    this.submenus = resp.menus.filter((value, index) => mainMenuIndex !== index) // submenus
                    this.originalMenu = Object.assign({}, resp.menus[0])
                } else {
                    let menu = { 
                        name: `Menu Principal`,
                        // name: `Menu Principal ${this.user.channelId || this.user.roleId}`,
                        topMenuId: undefined,
                        refObjectMenus: undefined,
                        channelId: this.user.channelId || this.user.roleId,
                    }
                    let respCreate = await api.createMenu(menu)
                    // console.log('resp createMenu',resp)
                    if((respCreate.statusCode != 200 && respCreate.statusCode != 201) || !respCreate.menu) {
                        let msg = {
                            text: "Ocorreu um erro ao criar menu!",
                            success: false
                        }
                        this.$emit('msg',msg)
                    } else {
                        this.menu = respCreate.menu
                    }
                }
            }
        },
        async getMenuTemplates(page = 1) {
            const respTemplates = await api.getMenuTemplatesPagination(this.user.channelId || this.user.roleId, page)  
            console.log({respTemplates})
            if(respTemplates.statusCode === 200) {
                this.templates = respTemplates?.templates
                this.templatesTotal = respTemplates?.total
            } else {
                this.templates = []
                this.templatesTotal = 0
            }
        },
        async getMenuOptions(menuId) {
            let resp = await api.getMenuOptions(menuId)
            if(resp.statusCode != 200) {
                let msg = {
                    text: "Ocorreu um erro ao carregar menus!",
                    sucess: false
                }
                return this.$emit('msg',msg)
            }

            let menu
            if(this.itemSelected?.submenu)
                menu = this.itemSelected.submenu
            else
                menu = this.menu
            if(menuId == menu._id) {
                menu.menus = resp.options
                this.$set(menu,'menus',resp.options)
            }
        },
        selectItem(item,topMenu,$event=null) {
            if(this.itemSelected) {
                this.saveMenu()
            }
            this.itemSelected = item;
            if(item.action == 'submenu') {
                this.checkAction(item)
            }
            if(topMenu._id) {
                this.itemSelected.topId = topMenu._id
            }
            if($event) {
                this.toggleDialogEmojiIcon();
            }
        },
        selectItemToDelete(item) {
            this.itemToDelete = item;
            if(item.action == 'submenu') {
                this.checkAction(item)
            }
        },
        toggleDialogEmojiTextarea() {
            this.showDialogTextarea = !this.showDialogTextarea;
        },
        toggleDialogEmojiSubmenuTextarea() {
            this.showDialogSubmenuTextarea = !this.showDialogSubmenuTextarea;
        },
        toggleDialogEmojiIcon() {
            this.showDialogIcon = !this.showDialogIcon;
        },
        selectEmojiTextarea(emoji) {
            this.menu.text += emoji.data
            this.toggleDialogEmojiTextarea()
        },
        selectEmojiSubmenuTextarea(emoji) {
            this.itemSelected.submenu.text += emoji.data
            this.toggleDialogEmojiSubmenuTextarea()
        },
        selectEmojiIcon(emoji) {
            this.itemSelected.icone = emoji.data
            this.toggleDialogEmojiIcon()
            this.itemSelected = null
        },
        setInputMedia(e) {
            console.log(e)
        },
        checkMove: function() {
            let menu = null
            if(this.itemSelected?.submenu) {
                menu = this.itemSelected.submenu
            } else {
                menu = this.menu
            }
            menu.menus.forEach((element,i) => {
                element.option = `${i+1}`
            });
        },
        verifyOption(array) {
            let order = 1
            array.forEach((element) => {
                if(element.option>=order) {
                    order = parseInt(element.option)+1
                }
            });
            return order
        },
        async addMenu() {
            let menu = null
            if(this.itemSelected?.submenu) 
                menu = this.itemSelected?.submenu
            else
                menu = this.menu
            
            let option = 1
            if(menu?.menus?.length)
                option = this.verifyOption(menu.menus)
            let op = {
                option: option.toString(),
                icone: '',
                textOption: 'Novo Menu',
            }
            // await api.addMenuOption(menu._id, op)
            // this.getMenuOptions(menu._id)
            this.menu.menus.push(op)
        },
        deleteEl(element) {
            let order = element.option
            let menu = null
            if(this.itemSelected?.submenu) {
                menu = this.itemSelected.submenu
            } else {
                menu = this.menu
            }
            menu.menus = menu.menus.filter(function(el) { return el != element; });
            if(this.$refs['modal-removeMenu']) {
                this.$refs['modal-removeMenu'].hide()
            }
            this.itemToDelete = undefined
            // console.log(menu.menus)
            menu.menus.forEach(el=>{
                if(el.option>order) {
                    el.option--   
                    el.option=el.option.toString()
                }
                return el
            })
        },
        closeDelete() {
            this.remove = !this.remove
        },
        fileExt(filename) {
            if(filename && filename.split('').reverse().join('')) {
                let ext = filename.split('').reverse().join('').split('.')[0].split('').reverse().join('');
                return ext
            }
            return ''
        },
        async getOperators() {
            let resp = await api.getOperators(this.user.channelId || this.user.roleId, true)
            if(resp.statusCode!=200) {
                this.operators = []
                return
            }
            this.operators = resp.operators
        },
        async getDepartments() {
            let resp = await api.getDepartments(this.user.channelId || this.user.roleId, true)
            if(resp.statusCode!=200) {
                this.departments = []
                return
            }
            this.departments = resp.departments
        },
        async getRedirectData() {
            this.getOperators()
            this.getDepartments()
        },
        async selectMenuImage(e) {
            console.log(e)
            if (e.size > 10000000) {
                let msg = {
                    text: "O tamanho máximo permitido para envios é de 10 MB",
                    danger: true
                }
                this.$emit('msg',msg)
            }
            const formData = new FormData();
            e.filename = e.name
            formData.append('file',e)
            let typefile = e['type'].split('/')[0]
            let token = getToken()
            let headers = {'Content-Type': 'multipart/form-data', 'type': 'file', 'typefile': typefile, 'authorization': token, 'clientNumber': this.user.channelId }
            let uploadResp = await api.upload(formData,headers)
            if(uploadResp.statusCode != 200) {
                let msg = {
                    text: "Ocorreu um erro! Tente novamente mais tarde!",
                    success: false,
                    countdown: 8
                }
                return this.$emit('msg',msg)
            }
            let url = uploadResp.file.uploadDir
            if(!this.menu.media?.length)
                this.menu.media.push({ url: '' })
            let obj = {
                url,
                type: typefile,
                filename: e.filename,
                ext: e.name.split('').reverse().join('').split('.')[0].split('').reverse().join('')
            }
            this.$set(this.menu.media,0,obj)
        },
        async saveMenu(advance = false) {
            if(!this.menu.menus.length) {
                let msg = {
                    text: "É Preciso adicionar um menu!",
                    success: false
                }
                return this.$emit('msg',msg)
            }
            // if(this.menu.interactive)
            //     delete this.menu.interactive
            if(this.menu.interactive?.type)
                this.menu.interactive.type = ""
            if(!this.menu.topMenuId)
                this.menu.topMenuId = undefined
            if(!this.menu.refObjectMenus)
                this.menu.refObjectMenus = undefined
            if(this.menu.isLinear)
                this.menu.isLinear = false
            
            if(this.menu.contentOrder?.length && this.menu.contentOrder.findIndex(el => el != 'text')) {
                let textIndex = this.menu.contentOrder.findIndex(el => el == 'text')
                if(textIndex >= 0) {
                    this.menu.contentOrder.splice(textIndex,1)
                }
            }
            let menu = this.menu
            // console.log('save menu',menu)
            let resp = null

            if(!menu._id) {
                menu.name = "Menu"
                menu.channelId = this.user.channelId || this.user.roleId
                resp = await api.createMenu(menu)
                if(resp.statusCode == 201) {
                    menu._id == resp.menu._id
                }
            } else {
                resp = await api.updateMenu({ menus: [ menu ] })
            }

            if(this.channels?.length) {
                await Promise.allSettled(
                    this.channels.map(async el => {
                        if(el.channelConfig) {
                            await api.updateChannelConfig(el.channelConfig)
                        }
                    })
                )
            }

            // console.log('resp save',resp)
            if(advance)
                this.goToMenuCustom(this.menu._id)
        },
        async checkAction(item) {
            if(item.action == 'submenu') {
                let resp = await api.getMenus(this.user.channelId || this.user.roleId)
                // console.log('resp',resp)
                if(resp.statusCode == 200) {
                    if(resp.menus.length) {
                        let mainMenuIndex = resp.menus.findIndex(el => !el.topMenuId)
                        let submenus = resp.menus.filter((value, index) => mainMenuIndex !== index) // submenus
                        let submenu = submenus.find(el => el.refObjectMenus == item._id)
                        // console.log('submenu',submenu)
                        if(submenu) {
                            this.$set(item,'submenu',submenu)
                            // item.submenu = submenu
                        } else {
                            let menu = { 
                                name: `submenu-${item._id}`,
                                topMenuId: item.topId || undefined,
                                refObjectMenus: item._id,
                                channelId: this.user.channelId || this.user.roleId,
                            }
                            let respCreate = await api.createMenu(menu)
                            // console.log('resp createMenu',resp)
                            if((respCreate.statusCode != 200 && respCreate.statusCode != 201) || !respCreate.menu) {
                                let msg = {
                                    text: "Ocorreu um erro ao criar menu!",
                                    success: false
                                }
                                this.$emit('msg',msg)
                            } else {
                                submenu = respCreate.menu
                                this.$set(item,'submenu',submenu)
                            }
                        }
                    }
                }
            }
        },
        async goToMain() {
            if(this.originalMenu && this.menu != this.originalMenu) {
                await api.updateMenu({ menus: [ this.originalMenu ] })
            }
            this.$router.replace({path:'/'})
        },
        async cancel() {
            this.$router.go(this.$router.currentRoute)
        },
        goToMenuCustom(menuId) {
            this.$router.replace({path:`/manageMenuCustom?id=${menuId}`})
        },
        async verifyTemplates() {
            this.loadingPreparations = true

            const hasSecondaryChannels = await this.channelHasSecondaryChannels()

            if(hasSecondaryChannels) {
                let channels = await this.getChannels()
                this.channels = this.channels.filter(el => !el?.channelConfig?.operationalChannelEnabled)
                if(channels?.length) {
                    this.showTemplates = true
                    this.menu = {}
                    // console.log({channels})
                    await this.getMenuTemplates()
                } else {
                    this.getMenus()
                }
            } else {
                this.getMenus()
            }

            this.loadingPreparations = false
        },
        async channelHasSecondaryChannels() {
            let resp = await api.getChannelHasSecondaryChannels(this.user.channelId || this.user.roleId)
            // console.log("resp",resp)
            if(resp.statusCode == 200) {
                return resp.hasSecondaryChannels
            }

            return false
        },
        async getChannels() {
            try {
                const resp = await api.getSecondaryChannels(this.user.channelId || this.user.roleId);
                if (resp.statusCode !== 200) {
                    this.channels = [];
                    return this.channels;
                }
                
                this.channels = resp.channels;
                await Promise.allSettled(this.channels.map(async (el) => {
                    const config = await api.getChannelConfig(el._id);
                    this.$set(el, 'channelConfig', config.channelConfig);
                }));
                
                if (this.channels.length) {
                    const channelResp = await api.getChannel(this.user.channelId || this.user.roleId)
                    if (channelResp.statusCode === 200) {
                        const channel = channelResp.channel?.channel;
                        if (channel) {
                            const config = await api.getChannelConfig(channel._id);
                            this.$set(channel, 'channelConfig', config.channelConfig);
                            this.channels.unshift(channel);
                        }
                    }
                }
            } catch (error) {
                console.error(error);
                this.channels = [];
            }
            
            return this.channels;
        },
        pickMenu(menu) {
            this.menu = menu
            this.templateChannels = this.associatedChannels(menu._id)
        },
        pageChange(page) {
            if(page != this.currentPage) {
                this.currentPage = page
                this.getMenuTemplates(page)
            }
        },
        associatedChannels(templateId) {
            return this.channels.filter(el => el?.channelConfig?.menuTemplateId == templateId)
        },
        async templateChannelSelected(channels) {
            await Promise.all(channels.map(async (channel) => {
                let index = this.channels.findIndex(c => c._id === channel._id);
                if (index >= 0 && this.channels[index]?.channelConfig?.menuTemplateId != this.menu._id) {
                    this.channels[index].channelConfig.menuTemplateId = this.menu._id;
                }
            }));

            let difference = this.channels.filter(
                c => !channels.some(
                    channel => channel._id === c._id
                )
            );

            difference.forEach(channel => {
                if(channel.channelConfig.menuTemplateId == this.menu._id)
                    channel.channelConfig.menuTemplateId = null
            });
        },
        async createTemplate() {
            let menu = {
                name: 'Novo Template',
                channelId: this.user.channelId || this.user.roleId
            }
            const resp = await api.createMenu(menu)
            if(resp.statusCode == 201) {
                this.$emit("msg", { text: "Template criado com sucesso!", success: true })
                this.getMenuTemplates()
            } else {
                this.$emit("msg", { text: "Ocorreu um erro!", success: false })
            }
        },
        async deleteTemplate(template) {
            const resp = await api.deleteMenu(template._id)
            if(resp.statusCode == 200) {
                this.$emit("msg", { text: "Template removido com sucesso!", success: true })
                this.getMenuTemplates()
            } else {
                this.$emit("msg", { text: "Ocorreu um erro!", success: false })
            }
        }
    },
    data() {
        return {
            remove: null,
            sendInputMedia: [],
            itemSelected: null,
            itemToDelete: null,
            showDialogTextarea: false,
            showDialogSubmenuTextarea: false,
            showDialogIcon: false,
            menu: {},
            originalMenu: {},
            submenus: [],
            departments: [],
            operators: [],
            modalEditCount: 0,
            secondaryChannels: [],
            templates: [],
            templatesTotal: 0,
            loadingPreparations: false,
            showTemplates: false,
            currentPage: 1,
            perPage: 9,
            templateChannels: [],
            evasivas: [
                {
                    name: 'Me',
                },
                {
                    name: 'Não',
                },
                {
                    name: 'Preciso',
                },
            ],
            tags: [
                {
                    name: '%NOME_CLIENTE%',
                    description: 'Identifica nome do contato',
                },
                {
                    name: '%DTN%',
                    description: 'Identifica mensagem de saudação de acordo com o horário de envio',
                },
                {
                    name: '#',
                    description: 'Voltar ao Menu Anterior',
                },
                {
                    name: '*',
                    description: 'Finalizar Atendimento',
                },
            ],
            i18n: {
                search: 'Pesquisar...',
                categories: {
                    Activity: "Atividades",
                    Flags: "Bandeiras",
                    Foods: "Comida",
                    Frequently: "Frequentes",
                    Objects: "Objetos",
                    Nature: "Natureza",
                    Peoples: "Pessoas",
                    Symbols: "Símbolos",
                    Places: "Locais"
                }
            }
        }
    }
}
</script>

<style>
    .cardpresentationcheckbox input{
        margin-right: 10px;
    }
    .cardpresentationcheckbox label{
        color: #555;
    }
    .emoji-picker #Categories {
        order: 5;
    }
    .emoji-picker #InputSearch {
        order: 4;
    }
    .emoji-picker .border{
        border: none !important;
    }
    .emoji-picker .emoji{
        display: flex !important;
        justify-content: center !important;
    }
    .emoji-picker .container-emoji{
        overflow: auto !important;
    }
    #modal-carimbo .close, #modal-evasivas .close, #modal-tags .close{
        border: none;
        background-color: transparent;
        font-size: 2em;
        color: #555;
    }
    #modal-carimbo .modal-title, #modal-evasivas .modal-title, #modal-tags .modal-title{
        color: #555 !important;
    }
    #modal-carimbo .modal-footer, #modal-evasivas .modal-footer, #modal-tags .modal-footer{
        display: none;
    }
    #modal-carimbo .col-form-label, #modal-evasivas .col-form-label, #modal-tags .col-form-label{
        color: #777;
        font-weight: 500;
    }
    .modal-content{
        border: none
    }
    .addimage .custom-file-input{
        display: none;
    }
    .addimage .custom-file-label{
        display: block;
        cursor: pointer;
    }
    .tooltip-orderChange>.tooltip-inner {
        color: #fff;
    }
    .formFile .custom-file-input{
        display: none !important;
    }
    .formFile .custom-file-label{
        cursor: pointer;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
    }
</style>

<style scoped>
    .col-4 {
        flex: 0 0 auto;
        width: 32% !important;
    }
    .page-managemenu .card-body{
        padding: 0;
    }
    .managemenurow{
        display: flex;
        justify-content: space-between;
    }
    .card{
        border: none;
    }
    .cardheader{
        padding: 24px;
        display: flex;
        align-items: center;
        border-bottom: 0.5px solid #eee;
    }
    .cardnumber{
        margin-right: 15px;
        background-color: hsl(94deg 44% 65%);
        color: #fff;
        display:inline-block;
        font-size:25px;
        font-weight: 500;
    }
    .cardnumber:before,
    .cardnumber:after {
        content:'\200B';
        display:inline-block;
        line-height:0px;

        padding-top:50%;
        padding-bottom:50%;
    }
    .cardnumber:before {
        padding-left:12px;
    }
    .cardnumber:after {
        padding-right:12px;
    }
    .cardtitle{
        display: inline;
        margin-top: 6px;
        color: #333;
    }
    .cardsubheader{    
        font-size: 0.9em;
        color: hsl(252deg 3% 68%);
        border-bottom: 0.5px solid #eee;
    }
    .cardpresentation{
        height: 100%;
    }
    .cardpresentationimg{
        display: flex;
        flex-direction: column;
        align-items: center;
        align-self: center;
        color: #fff;
        background-color: hsl(249deg 9% 28%);
        padding: 79px;
        cursor: pointer;
        width: 100%;
    }
    .custom-file-label:hover .cardpresentationimg{
        background-image: linear-gradient(rgba(0, 0, 0, 0.1) 0 0);
    }
    .cardpresentationicon{
        font-size: 4em;
    }
    .cardpresentationtext{
        font-size: 1.2em;
    }
    .cardpresentationcontent{
        padding: 20px 24px;
    }
    .cardmainmenucontent{
        padding: 24px;
    }
    .cardpresentationcontenttop{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
    }
    .managemenubtn:not(:last-child) {
        margin-right: 10px;
    }
    .managemenubtn{
        padding: 5px 20px;
    }
    .managemenubtnpink{
        background-color: hsl(339deg 81% 69%);
        border-color: hsl(339deg 81% 69%);
        padding: 5px 20px;
    }
    .managemenubtnred{
        background-color: hsl(360deg 83% 69%);
        border-color: hsl(360deg 83% 69%);
        padding: 5px 20px;
    }
    .managemenubtnwhite{
        background-color: #fff;
        color: #777;
        border-color: #eee;
    }
    .managemenubtnwhite:hover{
        background-color: #777;
        color: #fff 
    }
    select, input, textarea{
        border-color: #eee;
        color: #555;
    }
    .cardpresentationcontentbottom{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 20px;
    }
    .cardmainmenuformline{
        display: flex;
        margin-bottom: 18px;
    }
    .cardmainmenuitem{
        display: flex;
        align-items: center;
        text-align: center;
        height: 50px;
        border-radius: 2px;
    }
    .cardmainmenuitem:not(:last-child) {
        margin-right: .5em;
    }
    .cardmainmenunumber{
        width: 60px;
    }
    .cardmainmenuicon{
        background-color: hsl(142deg 38% 59%);
        border-color: hsl(142deg 38% 59%);
        width: 4rem;
    }
    .cardmainmenuicon:hover{
        background-color: hsl(142deg 30% 50%);
        border-color: hsl(142deg 30% 50%);
    }
    .cardmainmenuname{
        margin: 0 !important;
        text-align: left;
    }
    .input-group .input-group-append{
        height: 100%;
    }
    .cardmainmenueyebtn{
        background-color: hsl(339deg 81% 69%);
        border-color: hsl(339deg 81% 69%);
        font-size: 1.2em;
    }
    .cardmainmenueditbtn{
        background-color: hsl(93deg 44% 65%);
        border-color: hsl(93deg 44% 65%);
        font-size: 1.2em;
    }
    .cardmainmenuremovebtn{
        background-color: hsl(360deg 83% 69%);
        border-color: hsl(360deg 83% 69%);
        font-size: 1.2em;
    }
    .cardmainmenubtns{
        display: flex;
        justify-content: flex-end;
    }
    .cardbtns{
        margin-top: 25px;
        display: flex;
        align-items: center;
        padding: 20px;
    }
    .form-control, .custom-select{
        padding: 0.375rem 0.75rem;
    }
    .modalinputrow{
        margin-bottom: 10px;
    }
    .modal select{
        width: 100%;
    }
    .evasivas{
        background-color: hsl(0deg 0% 93%);
        color: hsl(0deg 0% 60%);
        width: fit-content;
        padding: 2px 10px;
        border-radius: 5px;
        display: inline;
    }
    .evasivas:not(:last-child) {
        margin-right: 10px;
    }
    .toggle-emoji{
        position: absolute;
        bottom: 0;
        right: 8px;
        background-color: transparent;
        border: none;
        color: #aaa;
        box-shadow: none !important;
        cursor: default;
    }
    .emoji-picker{
        position: absolute;
        bottom: 40px;
        right: 0;
        width: 250px;
        z-index: 1;
    }
    .textareaemoji-container, .iconemojipicker-container{
        position: relative;
        left: 0
    }
    .iconemojipicker{
        bottom: 0;
    }
    .modal-submenusDiv{
        border: 1px solid #eee;
        padding: 1em;
    }
    .modal-submenusDiv .cardmainmenubtns{
        justify-content: flex-start;
    }
</style>